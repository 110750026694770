/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { del, get, getCB, getYC, post, postCB, postYC, put, putCB, putYC } from 'm6BoBuilder/services/ApiManager';
import { HydraBase, HydraList } from 'm6BoBuilder/interfaces/hydra.interface';
import {
    ImpersonateResponse,
    /*LoginResponse,
    PropsLogin,*/
    UnsubscribeResponse,
    UserDetailsUpdate,
    UserUpdate,
} from 'models/User';
import { ApiUser } from 'interfaces/user.interface';
import { Ingredient } from 'models/Ingredients';
import { Recipe } from 'models/Recipe';
import { Menu, MenuMealUpdate, MenuUpdate } from 'models/Menu';

// EXTERNAL RESOURCE //
export const externalResourceApi = {
    getAllCB: (resourcePath: string, params?: Record<string, any>) =>
        getCB<HydraList<HydraBase>>(`/${resourcePath}`, { params }),
    getAllYC: (resourcePath: string, params?: Record<string, any>) =>
        getYC<HydraList<HydraBase>>(`/${resourcePath}`, { params }),
    getCBById: (resourcePath: string, resourceId: number, params?: Record<string, any>) =>
        getCB<HydraList<HydraBase>>(`/${resourcePath}/${resourceId}`, { params }),
    getYCById: (resourcePath: string, resourceId: number, params?: Record<string, any>) =>
        getYC<HydraList<HydraBase>>(`/${resourcePath}/${resourceId}`, { params }),
};

// USER //
export const userApi = {
    users: () => get<HydraList<ApiUser>>(`/bo-users`),
    user: (userId: number) => get<ApiUser>(`/users/${userId}`),
    userLogs: (userId: number, params: Record<string, any>) => get<ApiUser>(`/users/${userId}/logs`, { params }),
    update: (userId: number, data: UserUpdate) => put<ApiUser>(`/users/${userId}`, data),
    unsubscribe: (id: number, data: any) => post<UnsubscribeResponse>(`/users/${id}/unsubscribe`, data),
    impersonate: (id: number) => get<ImpersonateResponse>(`/users/${id}/impersonate`),
    delete: (userId: number) => del(`/bo-users/${userId}`),
    merge: (userId: number, userToMerge: string) => post(`/merge_account/${userId}/${userToMerge}`),
};

export const userCBApi = {
    userDetails: (userId: number) => getCB<ApiUser>(`/user_details/${userId}`),
    create: (data: UserDetailsUpdate) => postCB<UserDetailsUpdate>(`/user_details`, data),
    update: (userId: number, data: UserDetailsUpdate) => putCB<UserDetailsUpdate>(`/user_details/${userId}`, data),
};

export const userYCApi = {
    userDetails: (userId: number) => getYC<ApiUser>(`/user_details/${userId}`),
    create: (data: UserDetailsUpdate) => postYC<UserDetailsUpdate>(`/user_details`, data),
    update: (userId: number, data: UserDetailsUpdate) => putYC<UserDetailsUpdate>(`/user_details/${userId}`, data),
};

// MENU //

export const menuApi = {
    getAll: (params: Record<string, any>) => get<HydraList<Menu>>(`/bo-menus`, { params }),
    updateMenu: (menuId: number, data: MenuUpdate) => put<MenuUpdate>(`/bo-menus/${menuId}`, data),
    createMenuMeal: (data: MenuMealUpdate) => post<MenuMealUpdate>(`/bo-meal-recipes`, data),
    updateMealRecipe: (mealRecipeId: number, data: MenuMealUpdate) =>
        put<MenuMealUpdate>(`/bo-meal-recipes/${mealRecipeId}`, data),
    deleteMealRecipe: (mealRecipeId: number) => del(`/bo-meal-recipes/${mealRecipeId}`),
    getVariantsByDietType: (dietTypeId: number, params: Record<string, any>) =>
        get<HydraList<Recipe>>(`/diet_types/${dietTypeId}/recipes`, { params }),
    getVariantsByMealRecipeId: (mealRecipeId: number, params: Record<string, any>) =>
        get<HydraList<Recipe>>(`recipes/variants/meal_recipes/${mealRecipeId}`, { params }),
};

export const recipeApi = {
    getAll: (params: Record<string, any>) => get<HydraList<Recipe>>(`/recipes`, { params }),
    get: (recipeId: number) => get<Recipe>(`/recipes/${recipeId}`),
};

export const ingredientApi = {
    getAll: (params: Record<string, any>) => get<HydraList<Ingredient>>(`/ingredients`, { params }),
    get: (ingredientId: number) => get<Ingredient>(`/ingredients/${ingredientId}`),
};
