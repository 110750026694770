import { memo, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, IconButton, useMediaQuery } from '@mui/material';
import { Close } from '@mui/icons-material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import { drawerWidth } from '../../../berry/store/constant';
import Navigation from '../../../components/layout/Sidebar/NavigationList';

// ==============================|| SIDEBAR DRAWER ||============================== //

interface SidebarProps {
    drawerOpen?: boolean;
    onClose: () => void;
}

const Sidebar = ({ drawerOpen, onClose }: SidebarProps) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = useMemo(
        () => (
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd ? '100vh' : 'calc(100vh - 88px)',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }}
            >
                <Navigation />
            </PerfectScrollbar>
        ),
        [matchUpMd],
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}>
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor={matchUpMd ? 'left' : 'right'}
                onClose={() => onClose()}
                open={drawerOpen}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: matchUpMd ? drawerWidth : '80%',
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px',
                        },
                    },
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {!matchUpMd && (
                    // mobile only
                    <Box sx={{ textAlign: 'right', mb: 2 }} onClick={() => onClose()}>
                        <IconButton size="large">
                            <Close fontSize="large" />
                        </IconButton>
                    </Box>
                )}
                {drawer}
            </Drawer>
        </Box>
    );
};

export default memo(Sidebar);
